<template>
  <div v-if="result.valueArray.length - 1 == result.order">
    <v-icon
      @click.stop="checkClick($event)"
      title="Delete"
      :size="size"
      :id="fieldAttributes.fieldAttrInput.id"
      >mdi-plus</v-icon
    >
  </div>
</template>
<script>
export default {
  props: {
    size: Number,
    result: {},
    fieldAttributes: {},
  },
  methods: {
    checkClick() {
      this.$emit("add-clicked");
    },
  },
};
</script>